import ModalDefault from "../../../../common/components/modal/modal-default";
import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import FieldsForm from "../../../../common/components/fields/fields-form";
import Resources from "../../../../data/services/resources";
import {CREATE_PERM, DEFAULT_METADATA_SELECT_SEARCH_QUERY} from "../../../../util/util-constants";
import {numberWithCommas, numberWithCommasToBack} from "../../../../util/util-formaters";
import {
    checkPerm,
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getLookup,
    getProp
} from "../../../../common/util/util-helpers";
import {fillFieldsFromData} from "../../../../common/util/util-fields";
import {LoaderLarge} from "../../../../common/components/loader";
import Button from "../../../../common/components/button";
import CreditMemoItemTable from "../credit-memo-item-table";
import FieldCellTextNew from "../../../../common/components/fields/field-cell-text-new";
import FieldCellSelectSearch from "../../../../common/components/fields/field-cell-select-search";
import FieldCellMoney from "../../../../common/components/fields/field-cell-money";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";
import {currentDate} from "../../../../common/util/util-dates";

export default function CreditMemoModal({
                                            show,
                                            onClose,
                                            isLoading,
                                            selectedItem,
                                            itemList,
                                            fetchOnMount,
                                            onSubmitEdit,
                                            onSubmitCreate,
                                            translate,
                                            dispatch,
                                            isReadOnly
                                        }) {
    // const dispatch = useDispatch();
    const isEditMode = !!selectedItem;
    const [fields, setFields] = useState(null);
    const [items, setItems] = useState(itemList)

    let totalAmount = (items ?? []).reduce((memo, it) => {
        memo = memo + numberWithCommasToBack(it.TotalAmount.value)
        return memo
    }, 0);


    function handleSubmitButtonClick() {
        const validatedFields = FieldsManager.validateFields(fields);
        let validatedItems = [...items];
        let itemsAreValid = true;

        if (items?.length) {
            validatedItems = validatedItems.map(item => {
                const validatedItem = FieldsManager.validateFields(item);
                if (!FieldsManager.checkFieldsForErrors(validatedItem)) {
                    itemsAreValid = false;
                }

                return validatedItem;
            })
        }

        if (FieldsManager.checkFieldsForErrors(validatedFields) && itemsAreValid) {
            const params = FieldsManager.getFieldKeyValues(fields);

            params.Items = (items ?? []).map(it => {
                return FieldsManager.getFieldKeyValues(it);
            });

            params.Amount = totalAmount;

            if (isEditMode) {
                params.CreditMemoID = selectedItem.CreditMemoID

                onSubmitEdit(params);
            } else {
                onSubmitCreate(params);
            }
        } else {
            setFields(validatedFields);
            setItems(validatedItems);
        }
    }

    function handleInputChange(name, value) {
        let fieldsUpdate = Object.assign({}, fields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);

        if ("ChargeTo" === name) {
            fieldsUpdate.ContactID.type = value === "2" ? "hidden" : "select-search";
            fieldsUpdate.ContactID.validate = value === "2" ? [''] : ['required'];
            fieldsUpdate.ContactID.value = '';
            fieldsUpdate.OrganizationID.type = value === "1" ? "hidden" : "select-search"
            fieldsUpdate.OrganizationID.validate = value === "1" ? [''] : ['required'];
            fieldsUpdate.OrganizationID.value = '';
        }

        fieldsUpdate[name].errorMessage = '';

        setFields(fieldsUpdate);
    }

    function handleAddInlineClick() {
        const itemsUpdate = [...items];
        itemsUpdate.push(getExpenseItemField())
        setItems(itemsUpdate);
    }

    function handleEditExpenseItem(item, index) {
        const itemsUpdate = [...items];

        itemsUpdate[index] = item;
        setItems(itemsUpdate);
    }

    function addNewExpenseItem(item) {
        const itemsUpdate = [...items];
        itemsUpdate.push(item);
        setItems(itemsUpdate);
    }

    function removeItem(index) {
        const itemsUpdate = [...items];

        itemsUpdate.splice(index, 1);
        setItems(itemsUpdate);
    }

    function handleItemInputChange(name, value, item) {
        const itemsUpdate = [...items];

        itemsUpdate[item.index][name].value = value;
        itemsUpdate[item.index][name].errorMessage = [];

        if ("Amount" === name || "Qty" === name) {
            itemsUpdate[item.index]["TotalAmount"].value
                = Number(itemsUpdate[item.index]["Amount"].value) * Number(itemsUpdate[item.index]["Qty"].value);
        }

        setItems(itemsUpdate);
    }

    const renderCells = {
        Description: (item) => {
            return <FieldCellTextNew
                name="Description"
                value={item?.['Description']}
                onChange={(name, value) => handleItemInputChange(name, value, item)}
                disabled={isReadOnly}
                autoFocus={!item.Description}
                errorMessage={items?.[item.index]?.['Description']?.errorMessage}
            />
        },
        AccountID: (item) => <FieldCellSelectSearch
            name="AccountID"
            value={{value: item?.['AccountID'], label: item?.['Account']}}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            disabled={isReadOnly}
            placeholder={translate("text.no_account_selected")}
            isClearable={true}
            select={getFieldsSelects()["AccountID"]}
            errorMessage={items?.[item.index]?.['AccountID']?.errorMessage}
        />,
        Amount: (item) => <FieldCellMoney
            name="Amount"
            value={item?.['Amount']}
            disabled={isReadOnly}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            errorMessage={items?.[item.index]?.['Amount']?.errorMessage}
        />,
        Qty: (item) => <FieldCellTextNew
            align="right"
            type="integer"
            name="Qty"
            value={item?.['Qty']}
            disabled={isReadOnly}
            onChange={(name, value) => handleItemInputChange(name, value, item)}
            errorMessage={items?.[item.index]?.['Qty']?.errorMessage}
        />
    }

    function getFields(item = {}) {
        if (item) {
            item.ChargeTo = item?.ContactID ? "1" : "2"
        }

        let fieldTemplates = {
            ChargeTo: new Field('ChargeTo', "2", [''], isReadOnly, 'button-group', {
                addContainerClass: 'col-span-full',
                data: {
                    1: translate('field.ContactID'),
                    2: translate('field.OrganizationID')
                },
                formLabelClass: 'hidden'
            }),
            OrganizationID: new Field('OrganizationID', "", item ? (item?.ChargeTo === "1" ? [''] : ['required']) : ['required'], isReadOnly, item ? (item?.ChargeTo === "1" ? "hidden" : "select-search") : "select-search", {addContainerClass: "col-span-6"}),
            ContactID: new Field('ContactID', "", item ? (item?.ChargeTo === "2" ? [''] : ['required']) : [''], isReadOnly, item ? (item?.ChargeTo === "2" ? "hidden" : "select-search") : "hidden", {addContainerClass: "col-span-6"}),

            Date: new Field('Date', currentDate(), ['required'], isReadOnly, 'date', {addContainerClass: "col-span-6"}),
            OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['required'], isReadOnly, 'select-search', {addContainerClass: "col-start-1 col-span-6"}),
            ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['required'], isReadOnly, 'select-search', {
                addContainerClass: "col-span-6",
                label: 'ContactDepartments'
            }),
            Description: new Field('Description', "", [''], isReadOnly, 'textarea', {
                heightClass: "!h-[11.25rem]",
                addContainerClass: "col-span-6"
            })
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);

        return fieldTemplates;
    }

    function getExpenseItemField(item = {}) {

        const fieldTemplates = {
            ItemNumber: new Field('ItemNumber', '', [''], false, 'hidden', {
                minWidth: "75",
                render: (it) => <div className="text-right">{it.index + 1}</div>
            }),
            Description: new Field('Description', '', ['empty'], false, 'textarea', {
                minWidth: "168",
                addContainerClass: 'col-span-full'
            }),
            AccountID: new Field('AccountID', '', ['empty'], false, 'select-search', {
                minWidth: "300",
                addContainerClass: 'col-span-full'
            }, {}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                minWidth: "150",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {
                isNegativeAllowed: true
            }),
            Qty: new Field('Qty', 1, ['empty'], false, 'float', {
                minWidth: "100",
                addContainerClass: 'col-span-full',
                addTableHeaderClass: 'mx-auto'
            }, {min: 1}),
            TotalAmount: new Field('TotalAmount', '', [], false, 'float', {
                render: (item) => (
                    <div className="text-right mr-7">{genericMoneyFormatter(item.TotalAmount)}</div>
                )
            }),
        }

        if (Object.keys(item).length > 0) {
            return fillFieldsFromData(fieldTemplates, item);
        }

        return fieldTemplates;
    }

    function getFieldsSelects() {
        return {
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            },
            OrganizationID: {
                api: 'api/' + Resources.OrganizationsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.LegalName + " - " + (item.AddressName ?? "")
                        + ", " + (item.CityName ?? "")
                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                        + (item.PostalCode ?? ""),
                    value: item.OrganizationID
                })
            },
            ContactID: {
                api: 'api/' + Resources.ContactsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (it) => ({
                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                    value: it.ContactID
                })
            },
            ContactGroupID: {
                api: 'api/' + Resources.ContactGroupsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.ContactGroupName,
                    value: item.ContactGroupID
                })
            },
        }
    }

    useEffect(() => {
        if (show) {
            setFields(getFields(selectedItem ?? null));
        }
    }, [show, selectedItem]);

    useEffect(() => {
        if (!isEditMode && show) {
            setItems([]);
        }

        if (isEditMode && fetchOnMount && !!show) {
            fetchOnMount();
        }
    }, [show]);

    useEffect(() => {
        if (!isLoading && itemList && isEditMode) {
            setItems(itemList.map(it => {
                return getExpenseItemField(it);
            }))
        }
    }, [itemList]);

    return (
        <ModalDefault
            show={show}
            title={isEditMode
                ? (isReadOnly ? translate("text.CreditMemoDetails") : translate('text.EditCreditMemo')): translate('text.create_credit_memo')}
            widthClass={'max-w-[100rem]'}
            buttonLabel={translate('btn.save')}
            onButtonClick={handleSubmitButtonClick}
            buttonDisabled={(items ?? []).length === 0 || !checkPerm(Resources.ExpenseRecurring, CREATE_PERM) || isReadOnly}
            onClose={onClose}
            closeButtonLabel={translate('btn.close')}
            translate={translate}
        >
            <div>
                {isLoading && (
                    <LoaderLarge/>
                )}

                <div className="p-6 grid grid-cols-12 gap-6">
                    {/* Left side */}
                    <div className="gap-4 col-span-7 grid grid-cols-12">
                        <FieldsForm
                            fieldsState={fields}
                            onChange={handleInputChange}
                            isLoading={isLoading}
                            selects={getFieldsSelects()}
                            translate={translate}
                        />
                    </div>

                    {/* Right side */}
                    <div className="col-span-5">
                        <div className="mb-4">
                            <TotalAmount
                                totalAmount={totalAmount}
                                translate={translate}
                            />
                        </div>

                        {/*<div className="col-span-full pb-8">*/}
                        {/*    {haveDocument && !!isEditMode && (*/}
                        {/*        <div className={"flex items-center"}>*/}

                        {/*            <button onClick={() => {*/}
                        {/*                handleToggleViewModal(false)*/}
                        {/*            }}>*/}
                        {/*                <EyeIcon className={'w-5 h-5'}/>*/}
                        {/*            </button>*/}


                        {/*            <div*/}
                        {/*                className={classNames(files[0]?.name ? 'line-through' : undefined, 'font-bold ml-2 mt-0.5')}>{translate('text.current_document')}</div>*/}

                        {/*            /!*<button*!/*/}
                        {/*            /!*    onClick={this.removeAttachment}*!/*/}
                        {/*            /!*    className="btn-text ml-4"*!/*/}
                        {/*            /!*>( Remove )</button>*!/*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*    <label*/}
                        {/*        className="font-bold m-1">{haveDocument ? translate('text.replace_document') : translate('text.upload_attachment')}</label>*/}

                        {/*    <Dropzone*/}
                        {/*        onDrop={(acceptedFiles) => {*/}
                        {/*            onDropAccepted(acceptedFiles)*/}
                        {/*        }}*/}
                        {/*        // onDragEnter={this.onDragEnter}*/}
                        {/*        onDragLeave={onDragLeave}*/}
                        {/*        onDropAccepted={onDrop}*/}
                        {/*        maxFiles={1}*/}
                        {/*        accept={'image/jpg,image/jpeg, image/png, .pdf'}*/}
                        {/*        maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}*/}
                        {/*        multiple={false}*/}
                        {/*    >*/}
                        {/*        {({getRootProps, getInputProps}) => (*/}
                        {/*            <section>*/}
                        {/*                <div {...getRootProps()}*/}
                        {/*                     className={*/}
                        {/*                         'px-6 py-10 rounded-card hover:border-primary border border-dashed border-tm-gray-400 mb-5 text-center text-break flex items-center justify-center' + (dropzoneActive ? ' active' : '')}>*/}
                        {/*                    <input {...getInputProps()} />*/}
                        {/*                    <div>*/}
                        {/*                        <p>*/}
                        {/*                            <em>{translate('field.image_types_and_pdf')}</em>*/}
                        {/*                        </p>*/}

                        {/*                        <em>{filesList.length !== 0 ? filesList : translate('text.no_file_selected')}</em>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </section>*/}
                        {/*        )}*/}
                        {/*    </Dropzone>*/}

                        {/*    {!!filesList.length && (*/}
                        {/*        <FileList*/}
                        {/*            onFileView={() => handleToggleViewModal(true)}*/}
                        {/*            files={files}*/}
                        {/*            onFileDelete={(file, i) => removeDocument(i)}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </div>

                <div className="bg-inverse mt-6 p-6">
                    <div
                        className="col-12 bg-tm-gray-100 py-5 rounded-card shadow-card border border-tm-gray-300 relative"
                    >
                        {isLoading && (
                            <LoaderLarge/>
                        )}

                        <CreditMemoItemTable
                            handleSelectFirstElement={() => null}
                            getFields={getExpenseItemField}
                            // addBtnRef={this.addBtnRef}
                            renderCells={renderCells}
                            items={(items ?? [])}
                            setItems={setItems}
                            onRemoveItem={removeItem}
                            translate={translate}
                            onAddNewItem={addNewExpenseItem}
                            onEditItem={handleEditExpenseItem}
                            isIncome={false} // true
                            dispatch={dispatch}
                            hideAddInlineItemButton={true}
                            isReadOnly={isReadOnly}
                        />

                        {!!items.length && (
                            <div
                                className="text-right my-5 mx-6"
                            >
                                <Button
                                    onClick={handleAddInlineClick}
                                    disabled={isReadOnly}
                                >
                                    Add and edit inline
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ModalDefault>
    )
}


function TotalAmount({totalAmount, translate}) {
    return <div className="text-right col-start-9 col-span-4">
        <div className="font-bold">
            <div
                className="text-lg">{translate('field.total_amount')}</div>
        </div>

        <div className="text-lg">{numberWithCommas(totalAmount)}</div>
    </div>
}