import {classNames, setTableProfile} from "../../../../util/util-helpers";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import {TrashIcon} from "@heroicons/react/24/outline";
import Button from "../../../button";
import React, {useEffect, useRef, useState} from "react";
import FieldText from "../../../fields/field-text";
import {useLocation} from "react-router-dom";
import LocalStorage from "../../../../../util/localStorage";
import PlusIcon from "@heroicons/react/20/solid/PlusIcon";
import {LoaderSmall} from "../../../loader";
import {InformationCircleIcon} from "@heroicons/react/20/solid";
import Tooltip from "../../../tooltip";
import ModalConfirm from "../../../modal/modal-confirm";

export default function TableFilterProfileTabs({translate, isLoading, defaultStoragePath, onStoragePathChange}) {
    const inputRef = useRef();
    const location = useLocation();
    const pagePath = defaultStoragePath ?? location.pathname.slice(1)
    const getDefaultTabs = [
        {id: 0, name: 'Primary profile', current: true, enabled: true},
        {id: 1, name: 'Profile #1', current: false, enabled: false},
        {id: 2, name: 'Profile #2', current: false, enabled: false},
        {id: 3, name: 'Profile #3', current: false, enabled: false},
    ]

    const [tabs, setTabs] = useState(getTabs());
    const [label, setLabel] = useState(tabs.find(it => it.current).name);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [isEditLabelMode, setIsEditLabelMode] = useState(false);
    const [areActionsVisible, setAreActionsVisible] = useState(true);

    const hasDisabledTabs = tabs.findIndex(it => !it.enabled) !== -1


    function getTabs() {

        const tableProfile = LocalStorage.get(pagePath + "_current_table_profile");

        return tableProfile ? tableProfile : getDefaultTabs;
    }

    function handleTabChange(tab) {
        const tabsUpdate = tabs.map(it => {
            it.current = it.id === tab.id;

            return it;
        });

        setTabs(tabsUpdate);
        setLabel(tab.name);
        setIsEditLabelMode(false);
        setTableProfile(pagePath, tabsUpdate);
        onStoragePathChange(tab);
        setAreActionsVisible(false);
        setTimeout(() => {
            setAreActionsVisible(true);
        }, 1000);
    }

    function handleLabelChange() {
        const tabsUpdate = tabs.map(it => {
            if (it.current) {
                it.name = label;
            }

            return it;
        });
        setTabs(tabsUpdate);
        setIsEditLabelMode(false);
        LocalStorage.set(pagePath + "_current_table_profile", tabsUpdate);
    }

    function handleEnableTab() {
        const firstDisabledIndex = tabs.findIndex(it => !it.enabled);
        let selectedTab = tabs[0];

        const tabsUpdate = tabs.map((it, index) => {
            if (index === firstDisabledIndex) {
                it.enabled = true;
                selectedTab = it;
            }

            return it;
        });

        handleTabChange(selectedTab);
        setTabs(tabsUpdate);
        setIsEditLabelMode(true);
    }

    function handleEditLabelClick() {
        setIsEditLabelMode(true);
    }

    function handleDeleteClick(tab) {
        setIsConfirmModalOpen(true);
        setSelectedItem(tab);
    }

    function deleteTab() {
        let deleteID = 0;
        const tabsUpdate = tabs.map(it => {
            if (it.current) {
                it.enabled = false;
                deleteID = it.id
                it.name = `Profile #${it.id}`
            }

            return it;
        });

        tabsUpdate[0].current = true;
        setTabs(tabsUpdate);
        handleTabChange(tabs[0]);
        setIsEditLabelMode(false);
        LocalStorage.remove(pagePath + "_table_profile_" + deleteID + "_state");
        LocalStorage.remove(pagePath + "_table_profile_" + deleteID + "_column_filters");
    }

    useEffect(() => {
        if (isEditLabelMode && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditLabelMode]);

    return (
        <div className="">
            <nav aria-label="Tabs" className="isolate rounded-lg flex gap-2">
                {tabs.filter(tab => tab.enabled).map((tab) => {
                    const DynamicTag = tab.current ? "div" : "button";
                    return (
                        <div key={tab.id} className="relative group">
                            <DynamicTag
                                onClick={() => tab.current ? null : handleTabChange(tab)}
                                disabled={isLoading}
                                className={
                                    classNames(
                                        "py-1 h-9 flex items-center text-sm rounded-btn w-full text-left",
                                        tab.current ? "bg-primary text-primary-contrast" : "text-tm-gray-800",
                                        isEditLabelMode && tab.current
                                            ? "px-4"
                                            : tab.id
                                                ? "px-8"
                                                : "pl-4 pr-8",
                                    )
                                }
                            >
                                {
                                    isEditLabelMode && tab.current
                                        ? <FieldText
                                            innerRef={inputRef}
                                            value={label}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    handleLabelChange();
                                                }
                                            }}
                                            onBlur={handleLabelChange}
                                            addClass="max-w-[130px] text-tm-gray-700 h-7 border border-tm-gray-300 placeholder:text-tm-gray-500 bg-field shadow-sm py-1 px-3 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-50 disabled:text-tm-gray-500 focus:text-tm-gray-700 rounded-input"
                                            onChange={(_, value) => setLabel(value)}
                                            placeholder={"Label name"}
                                        />
                                        : <span>
                                            {tab.name}

                                            {!tab.id && (
                                                <Tooltip
                                                    content="You can create up to three additional profiles. The primary profile cannot be deleted."
                                                >
                                                <span className="absolute z-10 top-2 right-1.5 w-5 h-5">
                                                    <InformationCircleIcon
                                                        className={
                                                            classNames(
                                                                "w-5 h-5",
                                                                tabs?.[0]?.current ? "text-white" : "text-primary"
                                                            )
                                                        }
                                                    />
                                                 </span>
                                                </Tooltip>
                                            )}
                                    </span>
                                }
                            </DynamicTag>

                            {(tab.current && (!areActionsVisible || isLoading)) && (
                                <LoaderSmall textClass="text-primary-contrast"/>
                            )}

                            {tab.current && !!tab.id && !isEditLabelMode && !isLoading && areActionsVisible && (
                                <div
                                    className="hidden group-hover:flex absolute justify-center h-9 py-1 top-0 right-0 left-0 gap-4">
                                    <button
                                        onClick={handleEditLabelClick}
                                        className="group-hover:bg-popup p-1 rounded-md text-tm-gray-900"
                                    >
                                        <PencilIcon className="w-5 h-5"/>
                                    </button>

                                    <button
                                        onClick={() => handleDeleteClick(tab)}
                                        className="group-hover:bg-popup p-1 rounded-md text-tm-gray-900">
                                        <TrashIcon className="w-5 h-5"/>
                                    </button>
                                </div>
                            )}
                        </div>
                    )
                })}

                {hasDisabledTabs && (
                    <div className="justify-end h-9 flex items-end pl-4">
                        <Button
                            onClick={handleEnableTab}
                            className="btn btn-outline px-2"
                        >
                            <PlusIcon className="w-5 h-5"/>
                        </Button>
                    </div>
                )}
            </nav>

            <ModalConfirm
                title={'Confirm'}
                show={isConfirmModalOpen}
                width={"2xl"}
                text={translate('text.delete_table_profile_confirm', [selectedItem.name])}
                onClose={() => setIsConfirmModalOpen(false)}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    deleteTab();
                    setIsConfirmModalOpen(false);
                }}
            />
        </div>
    )
}