import React from "react";
import {ChevronRightIcon} from "@heroicons/react/24/outline";
import {classNames} from "../../../../common/util/util-helpers";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {genericMoneyFormatter} from "../../../../common/util/util-vanilla";

export default function LoadInfoPricing({
                                            innerRef,
                                            isSectionExpanded,
                                            toggleExpandSection,
                                            fields,
                                            selects,
                                            onInputChange,
                                            allowOverLimit,
                                            customerAvailableCredit,
                                            onRequestCreditClick,
                                            translate
                                        }) {
    return (
        <React.Fragment>
            <div ref={innerRef} className="grid grid-cols-12 px-6 relative py-3 border-t border-tm-gray-300">
                <div className="col-span-5 py-3 pr-3">
                    <div className="flex items-center">
                        <button
                            className="btn btn-icon -ml-2 md mr-2 w-8 h-8"
                            onClick={() => toggleExpandSection("LoadInfoPricing")}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        "w-5 h-5",
                                        isSectionExpanded ? "rotate-90" : undefined
                                    )
                                }
                            />
                        </button>

                        <Subtitle subtitle={translate("text.load_info_pricing")}/>
                    </div>

                    {customerAvailableCredit && (
                        <div className="mt-3 space-y-2">
                            <InfoBar
                                type={
                                    customerAvailableCredit > 0
                                        ? "default"
                                        : allowOverLimit
                                            ? "warning"
                                            : "danger"
                                }
                            >
                                <div className="flex w-full justify-between gap-2">
                                    <sapn className="text-base">Available credit {genericMoneyFormatter(customerAvailableCredit)}</sapn>
                                    <button
                                        onClick={onRequestCreditClick}
                                        className="btn btn-outline py-0 h-auto min-h-6"
                                    >
                                        Request Credit
                                    </button>
                                </div>
                            </InfoBar>
                        </div>
                    )}
                </div>

                <div
                    className={
                        classNames(
                            "pl-5 col-span-7 pb-3 pr-3",
                            customerAvailableCredit ? "pt-[38px]" : "pt-3"
                        )
                    }
                >
                    {isSectionExpanded && (
                        <div className="grid grid-cols-12 gap-4">
                            <FieldsToHtml
                                fieldsState={Object.values(fields)}
                                onInputChange={onInputChange}
                                selects={selects}
                                translate={translate}
                            />
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}
