import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import InfoTab from './infoTab'
import ReviewsTab from './reviewsTab'
import {showGlobalModal} from '../../../data/actions/ui'
import SettingsTab from './customer-settings-tab'
import LocalStorage from '../../../util/localStorage'
import {Field} from '../../../data/services/fields'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import CommissionsTab from './commissions-tab'
import Tippy from '@tippyjs/react'
import DocumentsCreateTab from "../../../common/components/tabs/documents-tab/create-documents";
import {download} from '../../../data/actions/download'
import Page from "../../../common/components/layout/layout-components/page";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import {CREATE_PERM, DELETE_PERM, READ_PERM, REFERENCE_TYPE_CUSTOMER, UPDATE_PERM} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDocumentTypesFor,
    getProp,
    getTabSettings,
    resourceIsUpdated
} from '../../../common/util/util-helpers'
import {currentDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {openInNewTab} from '../../../common/util/util-helpers'
import StarIcon from '@heroicons/react/20/solid/StarIcon'
import ClaimsTab from "./claims-tab";
import SummaryTab from "./summary-tab";
import {getThirdResource} from "../../../data/actions/thirdResource";
import {deleteDialogResource} from "../../../data/actions/dialogResource";
import CustomerCreditTab from "./credit-tab";
import ContactsTab from "../../../common/components/tabs/contacts-tab";
import ResourceListTab from "../../../common/components/tabs/resource-list-tab";
import DocumentsUpdateTab from "../../../common/components/tabs/documents-tab/update-documents";
import ExpiryItemsTab from "../../../common/components/tabs/expiry-items-tab";
import ResourceTasksDialog from "../../../common/components/modal/tasksDialogs/resourceTasksDialog";
import CompaniesPaymentTab from "../../../common/components/tabs/companies-payment-tab";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import Card from "../../../common/components/card";
import ModalDefault from "../../../common/components/modal/modal-default";
import MacroPointIntegrationTab from "./macro-point-tab";


class CustomerView extends Component {

    constructor(props) {
        super(props)

        let tabsSettings = getTabSettings('Companies', 'Customers');

        const tabs = [
            {
                name: 'CustomersInfo',
                resource: Resources.CustomersInfo,
                current: false,
                visible: true
            },
            {
                name: 'CustomerSummary',
                resource: Resources.CustomersSummary,
                current: false,
                visible: true
            },
            {
                name: 'CustomerPayment',
                resource: Resources.CustomersPayment,
                current: false,
                visible: true
            },
            {
                name: 'CustomersClaims',
                resource: Resources.CustomersClaims,
                current: false,
                visible: true
            },
            {
                name: 'CustomersCommissions',
                resource: Resources.CustomersCommissions,
                current: false,
                visible: true
            },
            {
                name: 'CustomersMatrix',
                resource: Resources.CustomersMatrix,
                current: false,
                visible: true
            },
            {
                name: 'CustomersAccessorials',
                resource: Resources.CustomersAccessorials,
                current: false,
                visible: false // Hidden
            },
            {
                name: 'CustomersCredit',
                resource: Resources.CustomersCredit,
                current: false,
                visible: true
            },
            {
                name: 'CustomersSettings',
                resource: Resources.CustomersSettings,
                current: false,
                visible: true
            },
            {
                name: 'CustomersContacts',
                resource: Resources.CustomersContacts,
                current: false,
                visible: true
            },
            /*
            {
                name: 'CustomerWOPricing',
                resource: Resources.CustomersInfo,
                current: false,
                visible: true
            },*/
            {
                name: 'CustomersReviews',
                resource: Resources.CustomersReviews,
                current: false,
                visible: true
            },
            {
                name: 'LoadReviews',
                resource: Resources.CustomersLoadReview,
                current: false,
                visible: true
            },
            {
                name: 'ExpiryItems',
                resource: Resources.CustomersExpiryItems,
                current: false,
                visible: true
            },
            {
                name: 'CustomersDocuments',
                resource: Resources.CustomersDocuments,
                current: false,
                visible: checkPerm(Resources.CustomersDocuments, READ_PERM),
            },
            {
                name: 'MacroPointCustomer',
                resource: Resources.Customers,
                current: false,
                visible: true
            }
        ].map(it => {
            if (!!tabsSettings && it.visible !== false) {
                it.visible = tabsSettings?.[it.name]?.isVisible !== false;
            }

            return it;
        }).filter((it) => checkPerm(it.resource, READ_PERM));

        const currentTab = this.getCurrentTab();
        tabs.map(it => {
            it.current = it.resource === currentTab;

            return it;
        })

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: currentTab,

            // Fields
            fields_documents: [],
            fields_notifications: this.getNotificationFields(),

            LegalName: '',
            ArchivedDate: '',
            isDirty: false,
            // Modals
            resourceTaskModalOpen: false,
            breakpoint: {}
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        if (this.getID()) {
            this.fetchData()
            this.fetchTaskResourceCount()
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.resource !== this.props.resource && this.state.selectedTab === Resources.CustomersInfo) {
            this.setState({
                ArchivedDate: getProp(this.props.resource, 'data.ArchivedDate', null),
            })
        }
        if (!prevProps.taskResource.update && !!this.props.taskResource.update && this.getID()) {
            this.fetchTaskResourceCount();
        }

        if (resourceIsUpdated(this.props.resource, prevProps.resource) && this.props.resource.resource === Resources.CustomersCredit) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.CustomersInfo,
            query: {
                id: this.getID()
            }
        }))
    }

    fetchTaskResourceCount = () => {
        this.props.dispatch(getCountTaskResource({
            user: LocalStorage.get('user'),
            resource: Resources.TaskResourceCount,
            query: {param: window.location.pathname}
        }))
    }

    onCustomersAccessorialsDownloadExcel = (query) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.CustomersAccessorials,
            query: Object.assign({
                id: this.getID(),
                format: 'EXCEL',
                name: 'customer_accessorials_' + currentDate() + '.xlsx'
            }, query)
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleCreateNewTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasksModal = () => {
        this.setState({
            resourceTaskModalOpen: !this.state.resourceTaskModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    canSwapTabsHandler = (isDirty = true) => {
        this.setState({isDirty: isDirty})
    }

    getNotificationFields = () => {
        return {
            ContactID: new Field('ContactID', '', []),
            ContactIDs: new Field('ContactIDs', '', []),
            CustomerLoadBooked: new Field('CustomerLoadBooked', '', [], false, 'checkbox'),
            CustomerPickupComplete: new Field('CustomerPickupComplete', '', [], false, 'checkbox'),
            CustomerDeliveryComplete: new Field('CustomerDeliveryComplete', '', [], false, 'checkbox'),
            EDI: new Field('EDI', '', [], false, 'checkbox'),
            ShipperDeliveryComplete: new Field('ShipperDeliveryComplete', '', [], false, 'checkbox'),
            ConsigneePickupComplete: new Field('ConsigneePickupComplete', '', [], false, 'checkbox'),
            ShipperTruckRadiusCheckbox: new Field('ShipperTruckRadiusCheckbox', '', []),
            ShipperTruckRadius: new Field('ShipperTruckRadius', '', ['integer_not_require']),
            ConsigneeTruckRadius: new Field('ConsigneeTruckRadius', '', ['integer_not_require']),
            ConsigneeTruckRadiusCheckbox: new Field('ConsigneeTruckRadiusCheckbox', '', []),
            SendCustomerNotifications: new Field('SendCustomerNotifications', '', [], false, 'checkbox'),
        }
    }

    getID = () => {
        return this.props.match.params.CustomerID
    }

    getCurrentTab = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab');

        return tab ?? Resources.CustomersInfo
    }

    getCustomerNameFromQuery = () => {
        const query = new URLSearchParams(this.props.location.search)
        return query.get('customer');
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, history, resource} = this.props;
        const TaskCount = getProp(this.props.taskResource, 'count.count', 0);
        const customerName = getProp(this.props.thirdResource, 'data.LegalName','');
        const infoData = getProp(this.props.thirdResource, 'data',[]);

        const openTaskCountTwoDigits = TaskCount > 9;

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
                hasFooter={
                    this.state.selectedTab === Resources.CustomersInfo
                    || this.state.selectedTab === Resources.CustomersSettings
                    || this.state.selectedTab === Resources.CustomersCredit
                    || this.state.selectedTab === Resources.CustomersPayment
                }
                isDirty={this.state.isDirty}
                customPageTitle={customerName}
            >
                <Page>
                    <div className="flex justify-between items-center">
                        <div className="flex justify-start items-center">
                            <GoBackButton
                                canSubmit={this.state.isDirty}
                                translate={translate}
                                history={history}
                                path={'/customers'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('text.customer')}
                                <span className="text-tm-gray-500"> - {customerName}
                                    {this.state.ArchivedDate && (
                                        <Tippy content={<span>Archived</span>}>
                                            <i className="iconsminds-delete-file"/>
                                        </Tippy>
                                    )}
                                </span>
                            </h1>

                            {!!infoData.CreditHold && (
                                <Tippy content={
                                    <span>{translate('field.CreditHold')} {infoData.CreditHoldUpdateDate ? `(${toFrontDateTimeFromUTC(infoData.CreditHoldUpdateDate)})` : ''} {infoData.CreditHoldNote ? ` (${infoData.CreditHoldNote})` : ''}</span>}>
                                    <div className="relative mr-3">
                                        <button
                                            type="button"
                                            className={classNames('w-full relative z-10 p-2 rounded-btn focus:outline-none focus:ring-2 focus:ring-offset-2 ', 'text-white bg-red-600')}
                                        >
                                            {translate('field.CreditHold')}
                                        </button>

                                        <span
                                            className="top-1 left-2 animate-ping absolute inline-flex h-7 w-16 rounded-btn bg-red-400 opacity-75"/>
                                    </div>
                                </Tippy>
                            )}
                        </div>

                        <div className="gap-2 flex flex-wrap items-center justify-end">
                            {checkPerm(Resources.Tasks, CREATE_PERM) && (
                                <div className="btn btn-outline mr-3"
                                     onClick={() => this.handleCreateNewTask()}>{translate('btn.new_customer_task')}
                                </div>
                            )}

                            {checkPerm(Resources.Tasks, READ_PERM) && (
                                <div className="relative">
                                    <button
                                        className="btn btn-outline"
                                        disabled={!checkPerm(Resources.TaskResource, READ_PERM)}
                                        onClick={() => this.handleToggleResourceTasksModal()}>

                                        {TaskCount > 0 && (
                                            <span
                                                className={
                                                    classNames(
                                                        "absolute z-10 flex -top-2.5 -right-2",
                                                        openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                    )
                                                }
                                            >
                                                          <div
                                                              className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[TaskCount]}</div>
                                                          <span
                                                              className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                          <span
                                                              className={
                                                                  classNames(
                                                                      "relative inline-flex rounded-full bg-primary",
                                                                      openTaskCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                                  )
                                                              }
                                                          />
                                                    </span>
                                        )}
                                        {translate('btn.show_tasks')}
                                        {this.props.taskResource.isCountLoading && `(${translate('text.Loading...')})`}
                                        {!this.props.taskResource.isCountLoading && TaskCount === 0 && `(${TaskCount})`}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block relative z-[33]">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="">
                        <div className="">
                            {this.state.selectedTab === Resources.CustomersInfo && (
                                <InfoTab
                                    canSwapTabsHandler={this.canSwapTabsHandler}
                                    {...this.props}/>
                            )}

                            {this.state.selectedTab === Resources.CustomersSummary && (
                                    <SummaryTab
                                        dispatch={this.props.dispatch}
                                        CustomerID={this.getID()}
                                        OrganizationID={this.props.match.params.OrganizationID}
                                        Organization={this.props.match.params.Organization}
                                        translate={translate}
                                        resource={this.props.resource}
                                        info={this.props.info}
                                    />
                            )}

                            {this.state.selectedTab === Resources.CustomersDocuments && this.getID() && (
                                <DocumentsUpdateTab
                                    id={this.props.match.params.OrganizationID}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    thirdResource={this.props.thirdResource}

                                    dialogTitle={'Customer Documents'}

                                    sortBy={'Description'}
                                    primaryKey={'OrganizationDocumentID'}

                                    download={this.props.download}
                                    resourceName={Resources.CustomersDocuments}

                                    documentUpdateFields={checkPerm(Resources.CustomersDocuments, UPDATE_PERM) && {
                                        DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}, {
                                            values: getDocumentTypesFor("IsCompanyDocument")
                                        }),
                                        ExpiryDate: new Field("ExpiryDate", '', [], false, 'date', {addContainerClass: 'col-span-full'},{isClearable: true}),
                                        Description: new Field("Description", '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                    }}

                                    documentTypes={getDocumentTypesFor("IsCompanyDocument")}
                                    searchFields={{
                                        query: new Field('query', '', [], false, 'search', {
                                            containerClass: 'col-md-4'
                                        }),
                                        DocumentTypeID: new Field('DocumentTypeID', '', [], false, 'select', {
                                            containerClass: 'col-md-4'
                                        }, {
                                            all: true,
                                            values: getDocumentTypesFor("IsCompanyDocument")
                                        }),
                                        DocumentExtension: new Field('DocumentExtension', '', [], false, 'select', {
                                            containerClass: 'col-md-4'
                                        }, {
                                            all: true
                                        }),
                                    }}
                                    readOnly={!checkPerm(Resources.CustomersDocuments, CREATE_PERM)}
                                    disableDelete={!checkPerm(Resources.CustomersDocuments, DELETE_PERM)}
                                />
                            )}

                            {this.state.selectedTab === Resources.CustomersDocuments && !this.getID() && (
                                <Card>
                                    <DocumentsCreateTab
                                        fields={this.state.fields_documents}
                                        translate={this.props.translate}

                                        dialogTitle={'Driver Documents'}

                                        documentTypes={getDocumentTypesFor("IsCompanyDocument")}
                                        handleUploadDocument={this.handleUploadDocument}
                                        handleDeleteDocument={this.handleDeleteDocument}
                                    />
                                </Card>
                            )}

                            {this.state.selectedTab === Resources.CustomersReviews && (
                                <ReviewsTab {...this.props} id={this.getID()}
                                            resourceName={Resources.CustomersReviews}/>
                            )}

                            {this.state.selectedTab === Resources.CustomersContacts && (
                                <ContactsTab
                                    {...this.props}
                                    id={this.getID()}
                                    resourceName={Resources.CustomersContacts}
                                    resourceImagePath={Resources.ContactImage}
                                    organizationID={this.props.match.params.OrganizationID}
                                    disableMemberOfOrganization
                                    hideSystemUser={true}
                                    primaryKey={'ContactInOrganizationID'}
                                    queryFilterFields={{
                                        DepartmentID: new Field('DepartmentID', '', [], false, 'select', {addContainerClass: "col-span-1"}, {isClearable: true}),
                                        limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true, labelType: "float"}, {menuPlacement: "top"})
                                    }}
                                    fields={{
                                        FirstName: new Field('FirstName', '', []),
                                        LastName: new Field('LastName', '', []),
                                        Email: new Field('Email', '', []),
                                        Phone: new Field('Phone', '', []),
                                        Department: new Field('Department', '', []),
                                        Notes: new Field('Notes', '', []),
                                    }}
                                    onDelete={(item, query) => {
                                        this.props.dispatch(deleteDialogResource({
                                            user: LocalStorage.get('user'),
                                            query: {
                                                ContactInOrganizationID: item.ContactInOrganizationID,
                                                ...query
                                            },
                                            piggyQuery: {
                                                ContactInOrganizationID: item.ContactInOrganizationID,
                                                ...query
                                            },
                                            errorMessage: true,
                                            successMessage: translate(`text.contact_deleted_from_${Resources.CustomersContacts}`, [`${item.FirstName} ${item.LastName}`]),
                                            resource: Resources.CustomersContacts,
                                            piggyResource: Resources.CustomersContacts
                                        }))
                                    }}
                                />
                            )}

                            {this.state.selectedTab === Resources.CustomersCommissions && (
                                    <CommissionsTab
                                        {...this.props}
                                        resourcePath={Resources.CustomersCommissions}
                                        resourceImagePath={Resources.ContactImage}
                                        CustomerID={this.getID()}
                                        translate={translate}
                                    />
                            )}

                            {this.state.selectedTab === Resources.CustomersSettings && (
                                <SettingsTab {...this.props} setIsStateDirty={(isDirty) => {this.setState({isDirty:isDirty })}}/>
                            )}

                            {this.state.selectedTab === Resources.CustomersCredit && (
                                <CustomerCreditTab {...this.props} setIsStateDirty={(isDirty) => {this.setState({isDirty:isDirty })}}/>
                            )}

                            {this.state.selectedTab === Resources.CustomersMatrix && (
                                <ResourceListTab
                                    tabKey={'CustomersMatrix'}
                                    id={this.getID()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    breakpoint={this.state.breakpoint}

                                    dialogTitle={'Customer Matrix'}

                                    sortBy={'CustomerMatrixID'}
                                    primaryKey={'CustomerMatrixID'}
                                    resourceName={Resources.CustomersMatrix}
                                    fields={{
                                        LoadPricingMatrixID: new Field('LoadPricingMatrixID', '', ['empty_select_search'], false, 'select-search', {addContainerClass: 'col-span-full'}),
                                        Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                    }}
                                    disableEdit
                                    searchFields={{
                                        limit: new Field('limit', 10, [''], false, 'select', {
                                            hideLabel: true,
                                            containerClass: 'col-md-4 limit-field-container float-right'
                                        })
                                    }}
                                    metadata={{
                                        LoadPricingMatrixID: {
                                            api: 'api/' + Resources.LoadPricingMatrix,
                                            query: {
                                                ExcludeIDs: getProp(resource.data, 'list', []).map(item => item.LoadPricingMatrixID).join(', ')
                                            },
                                            searchMap: (item) => ({
                                                value: item.LoadPricingMatrixID,
                                                label: item.MatrixName,
                                            })
                                        }
                                    }}
                                    addClassTableCard={"mt-2"}
                                />
                            )}

                            {this.state.selectedTab === Resources.CustomersClaims && (
                                    <ClaimsTab
                                        {...this.props}
                                        resourcePath={Resources.CustomersClaims}
                                        CustomerID={this.getID()}
                                        translate={translate}
                                    />
                            )}

                            {this.state.selectedTab === Resources.CustomersLoadReview && (
                                <ResourceListTab
                                    tabKey={'CustomersLoadReview'}
                                    id={this.getID()}
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}
                                    breakpoint={this.state.breakpoint}

                                    dialogTitle={'Load reviews'}

                                    sortBy={'LoadID'}
                                    primaryKey={'LoadID'}
                                    resourceName={Resources.CustomersLoadReview}
                                    fields={{
                                        LoadNumber: new Field('LoadNumber', '', [], false, 'custom', {
                                            render: (item) => {
                                                if (!item.LoadID
                                                ) return null;

                                                return (
                                                    <button
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            openInNewTab(`/loads/info/${item.LoadID}`);
                                                        }}
                                                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                                    >
                                                        {item.LoadNumber}
                                                    </button>
                                                )
                                            }
                                        }),
                                        CustomerRating: new Field('CustomerRating', '', [], false, 'custom', {
                                            render: (item) => {
                                                if (!item.CustomerRating) return null;

                                                return (
                                                    <div className={
                                                        classNames(
                                                            "flex items-center py-2 rounded-full items-center relative h-12",
                                                        )
                                                    }>
                                                        {new Array(10).fill(1).map((it, i) => {
                                                            return (
                                                                <div
                                                                    key={it}
                                                                    className="relative"
                                                                >
                                                                    <StarIcon
                                                                        className={
                                                                            classNames(
                                                                                "w-8 h-8 text-tm-gray-400",
                                                                            )
                                                                        }
                                                                    />
                                                                    <StarIcon
                                                                        className={
                                                                            classNames(
                                                                                "absolute w-8 h-8 z-1 bottom-px left-px",
                                                                                i < item.CustomerRating ? "text-yellow-400" : "text-tm-gray-300"
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="pl-2 text-base ml-auto">
                                                            {item.CustomerRating} out of 10
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }),
                                        CustomerReviewNote: new Field('CustomerReviewNote', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                    }}
                                    disableEdit
                                    disableCreate
                                    disableDelete
                                    searchFields={{
                                        limit: new Field('limit', 10, [''], false, 'select', {
                                            hideLabel: true,
                                            containerClass: 'col-md-4 limit-field-container float-right'
                                        })
                                    }}
                                    metadata={{}}
                                    addClassTableCard={"mt-2"}
                                />
                            )}

                            {/*{this.state.selectedTab === Resources.CustomersAccessorials && (*/}
                            {/*    <ResourceListTab*/}
                            {/*        tabKey={'CustomersAccessorials'}*/}
                            {/*        id={this.getID()}*/}
                            {/*        dispatch={this.props.dispatch}*/}
                            {/*        translate={this.props.translate}*/}
                            {/*        resource={this.props.resource}*/}
                            {/*        breakpoint={this.state.breakpoint}*/}

                            {/*        infoParagraph={'Values will be used for pricing when load is created for this customer.'}*/}

                            {/*        dialogTitle={'Customer Accessorial'}*/}

                            {/*        sortBy={'AccessorialID'}*/}
                            {/*        primaryKey={'CustomerAccessorialID'}*/}
                            {/*        resourceName={Resources.CustomersAccessorials}*/}
                            {/*        fields={{*/}
                            {/*            AccessorialID: new Field('AccessorialID', '', ['empty_select_search'], false, 'select-search', {addContainerClass: 'col-span-full'}),*/}
                            {/*            CustomerAccessorialUnits: new Field('CustomerAccessorialUnits', '', ['float_or_empty'], false, 'text', {addContainerClass: 'col-span-full'}),*/}
                            {/*            CustomerAccessorialAmount: new Field('CustomerAccessorialAmount', '', ['float_or_empty'], false, 'money', {addContainerClass: 'col-span-full'}),*/}
                            {/*            CustomerAccessorialNotes: new Field('CustomerAccessorialNotes', '', [''], false, 'textarea', {addContainerClass: 'col-span-full'})*/}
                            {/*        }}*/}
                            {/*        searchFields={{*/}
                            {/*            limit: new Field('limit', 10, [''], false, 'select', {*/}
                            {/*                hideLabel: true,*/}
                            {/*                containerClass: 'col-md-4 limit-field-container float-right'*/}
                            {/*            })*/}
                            {/*        }}*/}
                            {/*        metadata={{*/}
                            {/*            AccessorialID: {*/}
                            {/*                api: 'api/load/accessorial',*/}
                            {/*                query: {*/}
                            {/*                    limit: 50,*/}
                            {/*                    offset: 0,*/}
                            {/*                    ExcludeIDs: getProp(resource.data, 'list', []).map(item => item.AccessorialID)*/}
                            {/*                },*/}
                            {/*                searchMap: (item) => ({*/}
                            {/*                    value: item.AccessorialID,*/}
                            {/*                    label: item.Accessorial*/}
                            {/*                })*/}
                            {/*            }*/}
                            {/*        }}*/}

                            {/*        addClassTableCard={"mt-2"}*/}
                            {/*        downloadExcel={this.onCustomersAccessorialsDownloadExcel}*/}
                            {/*    />*/}
                            {/*)}*/}

                            {this.state.selectedTab === Resources.CustomersPayment && (
                                <CompaniesPaymentTab
                                    resourceName={Resources.CustomersPayment}
                                    OrganizationID={this.getID()}
                                    canSwapTabsHandler={this.canSwapTabsHandler}
                                    {...this.props}
                                />
                            )}

                            {this.state.selectedTab === Resources.CustomersExpiryItems && (
                                <ExpiryItemsTab
                                    translate={translate}
                                    resourceName={Resources.CustomersExpiryItems}
                                    Reference={customerName ?? ''}
                                    ReferenceID={this.getID()}
                                    ReferenceType={REFERENCE_TYPE_CUSTOMER}
                                />
                            )}

                            {this.state.selectedTab === Resources.Customers && (
                                <MacroPointIntegrationTab
                                    translate={translate}
                                    resource={this.props.resource}
                                    match={this.props.match}
                                    dispatch={this.props.dispatch}
                                />
                            )}
                        </div>
                    </div>


                </Page>

                <ModalConfirm
                    type="default"
                    title={this.props.translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        isDirty: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>

                <ModalDefault
                    show={!!this.state.resourceTaskModalOpen}
                    title={translate('modal_heading.customer_tasks')}
                    widthClass={'max-w-3xl'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={this.handleToggleResourceTasksModal}

                    translate={translate}
                >
                    <ResourceTasksDialog
                        close={this.handleToggleResourceTasksModal}
                        {...this.props}
                        large={true}
                    />
                </ModalDefault>
            </Layout>
        )
    }
}

export default connect(state => state)(CustomerView)
