import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import moment from 'moment'
import Env from '../../../util/env'
import Resources from '../../../data/services/resources'
import {createResource, updateResource} from '../../../data/actions/resource'
import Dropzone from 'react-dropzone'
import {
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    JOURNAL_ENTRY_TYPE_JOURNAL,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE
} from '../../../util/util-constants'
import {genericMoneyFormatter, scrollErrorIntoViewFields, toggleBodyScroll} from '../../../common/util/util-vanilla'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import {classNames, getLookup} from '../../../common/util/util-helpers'
import ModalHeader from "../../../common/components/modal/modal-header";
import ModalDefault from "../../../common/components/modal/modal-default";
import FileViewer from "../../../common/components/file-viewer/components";
import FieldText from "../../../common/components/fields/field-text";
import ModalFooter from "../../../common/components/modal/modal-footer";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import FieldsToHtml from "../../../common/components/fields/fields-to-html";
import FieldTextarea from "../../../common/components/fields/field-textarea";
import FieldContainer from "../../../common/components/fields/field-container";
import FieldDate from "../../../common/components/fields/field-date";
import {LoaderSmall} from "../../../common/components/loader";
import Modal from "../../../common/components/modal";
import DocumentCard from "../../../common/components/card/document-card";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {getJWT} from "../../../common/util/util-auth";
import ArrowsRightLeftIcon from "@heroicons/react/20/solid/ArrowsRightLeftIcon";
import ButtonIcon from "../../../common/components/button/button-icon";

export default class JournalCreateDialog extends Component {

    constructor(props) {
        super(props)
        const isLockedType = !!this.props?.selectedItem && this.props?.selectedItem?.JournalEntryTypeID !== JOURNAL_ENTRY_TYPE_JOURNAL
        this.state = {
            isLockedType: isLockedType,
            fields: this.getFields(null, isLockedType),
            items: this.getItems(this.props?.selectedItem?.Transactions, isLockedType),
            showErrorMessage: false,
            newFiles: [],
            uploadedFiles: this.props.selectedItem?.AttachPath ? this.getSelectedItemDocument() : [],
            Description: '',
            previewDocument: undefined,

            // MODALS
            isPreviewModalOpen: false,
            resourceDialog: false,
            filesUploadDialogHideAnimation: false,
            resourceDialogHideAnimation: false,
            confirmDialog: false,
            confirmDialogHideAnimation: false,
        }
    }

    componentDidMount() {
        toggleBodyScroll()
    }

    componentWillUnmount() {
        toggleBodyScroll()
    }

    /** Data Events
     ================================================================= */
    handleSubmitClick = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            items: this.state.items.map((fields) => FieldsManager.validateFields(fields)),
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields) && this.state.items.reduce((memo, fields) => (memo && FieldsManager.checkFieldsForErrors(fields)), true) && this.checkItemsForErrors()) {
                if (this.props.isCopyMode) {
                    // COPY
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields),
                            {
                                Items: this.state.items.map((fields) => Object.assign({}, FieldsManager.getFieldKeyValues(fields), {AccountID: fields.AccountID.value.value})),
                            }),
                        errorMessage: true, successMessage: 'Journal created successfully.',
                        query: this.props.query,
                        resource: Resources.Journal,
                        piggyResource: this.props.activeTab,
                        file: this.state.uploadedFiles,
                        fileResource: Resources.JournalDocuments,
                    }))
                } else if (this.props.selectedItem) {
                    // EDIT
                    let params = Object.assign(
                        {},
                        FieldsManager.getFieldKeyValues(this.state.fields),
                        {
                            Items: this.state.items.map((fields) => Object.assign({}, FieldsManager.getFieldKeyValues(fields), {AccountID: fields.AccountID.value.value})),
                            id: this.props.selectedItem.JournalEntryID
                        }
                    )

                    if (!this.state?.uploadedFiles?.length) {
                        params.AttachPath = '1'
                    }

                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        errorMessage: true, successMessage: 'Journal updated successfully.',
                        query: this.props.query,
                        resource: Resources.Journal,
                        piggyResource: this.props.activeTab,
                        file: this.state.uploadedFiles,
                        fileResource: Resources.JournalDocuments,
                    }))
                } else {
                    // CREATE
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields),
                            {
                                Items: this.state.items.map((fields) => Object.assign({}, FieldsManager.getFieldKeyValues(fields), {AccountID: fields.AccountID.value.value})),
                            }),
                        errorMessage: true, successMessage: 'Journal created successfully.',
                        query: this.props.query,
                        resource: Resources.Journal,
                        piggyResource: this.props.activeTab,
                        file: this.state.uploadedFiles,
                        fileResource: Resources.JournalDocuments,
                    }))
                }
                this.handleCloseClick()
            } else {
                scrollErrorIntoViewFields([this.state.fields, this.state.items])
            }
        })
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value, index) => {
        if (index !== undefined) { // Condition for Edit items (array)
            this.updateItemsFields(name, value, index)
        } else { // Condition for  Edit fields
            this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})
        }
    }

    handleDocumentInputChange = (name, value, index = null) => {
        let descriptions = this.state.Descriptions
        descriptions[index] = value
        this.setState({Descriptions: descriptions})
    }

    uploadDocument = () => {
        let tmp = this.state.newFiles
        tmp[0].description = this.state.Descriptions[0]
        this.setState({
            uploadedFiles: tmp,
            Descriptions: [],
            newFiles: []
        })
        this.cancelUpload()
    }

    handleCloseClick = () => {
        this.props.onClose()
    }

    handleReverseCreditAndDebtValuesClick = () => {
        const itemsUpdate = this.state.items.map(it => {
            const creditValBuffer = it.Credit.value;
            it.Credit.value = it.Debit.value;
            it.Debit.value = creditValBuffer;
            return it;
        })

        this.setState({items: itemsUpdate})
    }

    addFieldToItems = () => {
        this.setState({items: this.state.items.concat(this.getItemsSingleField())})
    }

    removeFieldFromItems = (index) => {
        this.setState({items: this.state.items.filter((_, i) => i !== index)})
    }

    updateItemsFields = (name, value, index) => {
        let item = this.state.items
        item[index][name].value = value
        this.setState({items: item})
    }

    /** Helpers
     ================================================================= */
    checkItemsForErrors = () => {
        const {totalDebit, totalCredit} = this.getTotals()
        if (totalDebit !== totalCredit || totalCredit === 0 || totalDebit === 0) {
            this.setState({showErrorMessage: true})
            return false
        }
        return true;
    }

    getItems = (items = {}, locked) => {
        if (Object.values(items).length < 2) {
            return [this.getItemsSingleField({}, 0, locked), this.getItemsSingleField({}, 1, locked)]
        }

        return this.props.selectedItem.Transactions.reduce((memo, item, index) => {
            memo.push(this.getItemsSingleField(item, index, locked))

            return memo
        }, [])
    }

    getFields = (locked) => {
        const item = this.props.selectedItem
        return {
            Name: new Field('Name', item?.Name ?? '', ['empty']),
            Description: new Field('Description', item?.Description ?? '', []),
            Date: new Field('Date', item?.Date ?? moment().format('YYYY-MM-DD HH:mm:ss'), ['empty'], locked),
        }
    }

    getSelectedItemDocument = () => {
        return [
            {
                fileName: this.props.selectedItem.AttachPath,
                JournalEntryID: this.props.selectedItem.JournalEntryID,
                AttachPath: this.props.selectedItem.AttachPath,
                name: this.props.selectedItem.AttachPath,
                path: this.props.selectedItem.AttachPath
            }
        ]
    }

    getItemsSingleField = (item = {}, index = 0, locked) => {
        const fieldTemplates = {
            TransactionID: new Field('TransactionID', '', [], true, 'hidden', {}),
            AccountID: new Field('AccountID', '', ['empty'], !!item.ReconciliationDate || locked, 'select-search', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }),
            Debit: new Field('Debit', `0`, ['float_or_empty'], !!item.ReconciliationDate || locked, 'money', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }, {
                addClass: 'form-control text-right'
            }),
            Credit: new Field('Credit', `0`, ['float_or_empty'], !!item.ReconciliationDate || locked, 'money', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }, {
                addClass: 'form-control text-right'
            }),
            Description: new Field('Description', '', [], false, 'text', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }),
            ContactID: new Field('ContactID', '', [], locked, 'select-search', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }, {isClearable: true}),
            OrganizationID: new Field('OrganizationID', '', [], locked, 'select-search', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }, {isClearable: true}),
            ProductServiceID: new Field('ProductServiceID', '', [], locked, 'select-search', {
                addContainerClass: 'col-span-1',
                labelType: 'float',
                hideLabel: true
            }, {isClearable: true}),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    getTotals = () => {
        return {
            totalDebit: Number(this.state.items.reduce((memo, it) => {
                return (memo) + Number(it.Debit.value.replace(/,/g, '')) ?? 0
            }, 0).toFixed(2)),
            totalCredit: Number(this.state.items.reduce((memo, it) => {
                return (memo) + Number(it.Credit.value.replace(/,/g, '')) ?? 0
            }, 0).toFixed(2))
        }
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        })
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        })
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
            filesUploadDialog: true
        })
    }

    cancelUpload = () => {
        this.setState({
            Descriptions: [],
            newFiles: [],
            filesUploadDialog: false
        })
    }

    deleteDocument = () => {
        this.setState({uploadedFiles: []})
    }

    togglePreviewModal = (document = null) => {
        this.setState({
            previewDocument: document,
            isPreviewModalOpen: !this.state.isPreviewModalOpen
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, isCopyMode} = this.props

        const rowCount = this.state.items?.length ?? 0

        const {totalDebit, totalCredit} = this.getTotals()

        const documents = this.state.uploadedFiles.map((document, index) => {
            return (
                <DocumentCard
                    key={document?.TaskDocumentID + index}
                    layoutSmall={true}
                    documentID={document.TaskDocumentID}
                    description={document.description || document.AttachPath}
                    fileName={document.name || document.AttachPath}
                    category={DocumentType[document.DocumentTypeID]}
                    date={document.CreateUpdateDate}
                    downloadDocument={() => {
                    }}
                    onPreviewDocument={() => this.togglePreviewModal(document)}
                    onDeleteDocument={() => this.deleteDocument(index, document)}
                    translate={this.props.translate}
                />
            )
        })
        const documentName = this.state.previewDocument?.Name ?? this.state.previewDocument?.name

        return (
            <React.Fragment>
                <Modal
                    show={true}
                    widthClass="max-w-full md:max-w-[120rem]"
                    onClose={() => this.handleCloseClick()}
                >
                    <ModalHeader
                        title={
                            isCopyMode
                                ? translate('modal_heading.copy_journal')
                                : !this.props.selectedItem
                                    ? translate('modal_heading.add_journal')
                                    : translate('modal_heading.edit_journal')
                        }
                        onClose={this.handleCloseClick}
                    />

                    {this.props.isLoading && (
                        <div className="p-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    <div className="p-6">
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-3">
                                <FieldContainer
                                    item={this.state.fields.Date}
                                    translate={translate}
                                >
                                    <FieldDate
                                        autoFocus={this.state.fields.Name.value}
                                        className={'form-control  select-css-search'}
                                        addClass={'form-control'}
                                        onChange={this.handleInputChange}
                                        showTimeSelect={false}
                                        minDate={this.props?.defaults?.LockedDate}
                                        {...this.state.fields.Date}
                                        disabled={this.state.isLockedType}
                                    />
                                </FieldContainer>
                            </div>
                            <div className="col-span-3">
                                <FieldContainer
                                    item={this.state.fields.Name}
                                    translate={translate}
                                >
                                    <FieldText
                                        className="form-control"
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.Name}
                                        disabled={this.state.isLockedType}
                                        placeholder={''} addClass={'form-control'}/>
                                </FieldContainer>
                            </div>
                            <div className="col-span-full">
                                <FieldContainer
                                    item={this.state.fields.Description}
                                    translate={translate}
                                >
                                    <FieldTextarea
                                        rows={2}
                                        onChange={this.handleInputChange}
                                        {...this.state.fields.Description}
                                        placeholder={''}
                                        addClass={'form-control whitespace-pre-wrap'}
                                    />
                                </FieldContainer>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Dropzone
                                    onDrop={(acceptedFiles) => {
                                        this.setState({
                                            newFiles: this.state.newFiles.concat(acceptedFiles.map(file => Object.assign(file, {
                                                preview: URL.createObjectURL(file)
                                            }))),
                                            Descriptions: acceptedFiles.map(() => ''),
                                            canSubmit: true,
                                        })
                                    }}
                                    onDragEnter={this.onDragEnter}
                                    onDragLeave={this.onDragLeave}
                                    onDropAccepted={this.onDrop}
                                    accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                    maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                    multiple={false}
                                >
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}
                                                 className={'h-20 my-2 border-2 flex items-center justify-center' + (this.state.dropzoneActive ? ' border-primary border-solid text-primary' : ' border-tm-gray-200 border-dashed text-tm-gray-700')}>
                                                <input {...getInputProps()} />

                                                <p className="m-0"
                                                   style={{userSelect: 'none'}}>{translate('field.drag_n_drop')}</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {documents}
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full items-center pr-16">
                        <div className="pl-6 pr-3 py-3 grid grid-cols-7 gap-4 w-full font-bold">
                            <div className="col-span-1">Account*</div>
                            <div className="col-span-1">Debit</div>
                            <div className="col-span-1">Credit</div>
                            <div className="col-span-1">Description</div>
                            <div className="col-span-1">Contact</div>
                            <div className="col-span-1">Organization</div>
                            <div className="col-span-1">Product/Service</div>
                        </div>
                    </div>

                    {this.state.items.map((row, rowIndex) => {
                        return (
                            <div key={rowIndex} className="flex items-center pr-6 w-full">
                                <div className="pl-6 pr-3 py-3 grid grid-cols-7 gap-4 w-full">
                                    <FieldsToHtml
                                        fieldsState={row}
                                        onInputChange={(name, value) => this.handleInputChange(name, value, rowIndex)}
                                        selects={{
                                            AccountID: {
                                                api: 'api/' + Resources.AccountsQuick,
                                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                                searchMap: (item) => ({
                                                    label: item.AccountNumber + ' ' + item.AccountName,
                                                    value: item.AccountID
                                                })
                                            },
                                            OrganizationID: {
                                                api: 'api/' + Resources.OrganizationsQuick,
                                                query: {},
                                                searchMap: (item) => ({
                                                    value: item.OrganizationID,
                                                    label: item.LegalName + " - " + (item.AddressName ?? "")
                                                        + ", " + (item.CityName ?? "")
                                                        + " " + (getLookup('State')[item.StateID] ?? "") + " "
                                                        + (item.PostalCode ?? ""),
                                                })
                                            },
                                            ContactID: {
                                                api: 'api/' + Resources.ContactsQuick,
                                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                                searchMap: (item) => ({
                                                    value: item.ContactID,
                                                    label: item.FirstName + ' ' + item.LastName,
                                                    Contact: item
                                                })
                                            },
                                            ProductServiceID: {
                                                api: 'api/' + Resources.ProductsServices,
                                                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                                searchMap: (item) => ({
                                                    value: item.ProductServiceID,
                                                    label: item.ProductService
                                                })
                                            }
                                        }
                                        }
                                        translate={translate}
                                    />
                                </div>

                                <button
                                    disabled={this.state.isLockedType || rowCount < 3}
                                    onClick={() => this.removeFieldFromItems(rowIndex)}
                                    className={
                                        classNames(
                                            'btn-table-action h-9 w-9 flex-shrink-0 flex items-center justify-center'
                                        )}
                                >
                                    <XMarkIcon
                                        className={
                                            classNames(
                                                'w-5 h-5',
                                                this.state.isLockedType || rowCount < 3 ? 'text-tm-gray-300' : undefined
                                            )
                                        }
                                    />
                                </button>
                            </div>
                        )
                    })}

                    <div className="flex w-full items-center pr-16 bg-tm-gray-100">
                        <div className="px-6 py-3 grid grid-cols-12 gap-4 w-full font-bold">
                            <div className="col-span-2 pl-3 leading-5">Total</div>
                            <div className="col-span-2 pr-3 text-right leading-5">{genericMoneyFormatter(totalDebit)}</div>
                            <div className="col-span-2 pr-3 text-right relative leading-5">
                                {(!!totalCredit || !!totalDebit) && !this.state.isLockedType && (
                                    <ButtonIcon
                                        tooltip="Reverse credit and debit values"
                                        addClass="!absolute -top-2 -left-2"
                                        onClick={this.handleReverseCreditAndDebtValuesClick}
                                    >
                                        <ArrowsRightLeftIcon className="w-5 h-5 text-primary"/>
                                    </ButtonIcon>
                                )}

                                {genericMoneyFormatter(totalCredit)}
                            </div>
                            <div className="col-span-4 pl-3 leading-5">
                                <div className="col-span-2 pl-3 relative"></div>
                            </div>
                        </div>
                    </div>

                    {!!this.state.showErrorMessage && (
                        <div className="p-6">
                            <InfoBar type="danger">
                                Please balance debits and credits.
                            </InfoBar>
                        </div>
                    )}

                    <ModalFooter
                        closeButtonLabel={translate('btn.cancel')}
                        onClose={this.handleCloseClick}
                        buttonLabel={isCopyMode ? translate('btn.create_copy') : translate('btn.save')}
                        onButtonClick={this.handleSubmitClick}
                    >
                        {!this.state.isLockedType && (<>
                            <button onClick={this.addFieldToItems} className="btn btn-primary">
                                {translate('btn.AddNewLine')}
                            </button>
                            <button onClick={() => this.setState({
                                items: [
                                    this.getItemsSingleField(),
                                    this.getItemsSingleField()
                                ]
                            })} className="btn btn-outline ml-2">
                                {translate('btn.ClearAllLines')}
                            </button>
                        </>)}
                    </ModalFooter>
                </Modal>

                {this.state.filesUploadDialog && (
                    <ModalDefault
                        {...this.props}
                        show={this.state.filesUploadDialog}
                        widthClass={"max-w-3xl"}
                        translate={translate}
                        title={translate('modal_heading.upload_confirm')}
                        name={this.state.newFiles.name}
                        close={this.cancelUpload}
                        buttonDisabled={false}
                        closeButtonLabel={translate('Close')}
                        onClose={this.cancelUpload}
                        buttonLabel={translate('Upload')}
                        onButtonClick={this.uploadDocument}
                    >
                        {this.state.newFiles.map((it, i) => {
                            return (
                                <div key={this.state.newFiles[i].name + i} className="form-group m-3">
                                    <label>{translate('text.desc_for_file')}: {this.state.newFiles[i].name}</label>
                                    <FieldText
                                        addClass="form-control"
                                        onChange={(name, value) => this.handleDocumentInputChange(name, value, i)}
                                        value={this.state.Descriptions[i]}
                                        placeholder=""
                                    />
                                    <div className="col-md-3"/>
                                </div>
                            )
                        })
                        }
                    </ModalDefault>
                )}

                <ModalDefault
                    show={!!this.state.isPreviewModalOpen}
                    title={translate('preview') + ' - ' + (documentName && documentName.length > 40 ? documentName.slice(0, 35) + '...' : documentName)}
                    className={'modal-md'}
                    widthClass={"max-w-7xl"}
                    limitHeight={true}
                    translate={translate}
                    onClose={this.togglePreviewModal}
                    closeButtonLabel={translate('Close')}
                >
                    {this.state.isPreviewModalOpen && (
                        <FileViewer
                            fileType={
                                this.state.uploadedFiles[0]?.path
                                    ? this.state.uploadedFiles[0].path.split('.').pop()
                                    : this.state.previewDocument?.path?.split('.')?.pop()
                            }
                            filePath={this.state.uploadedFiles?.[0]?.preview
                                ? this.state.uploadedFiles?.[0]?.preview
                                : Env.getApiUrl('api/' + Resources.JournalDocuments, Object.assign({}, {
                                    JournalEntryID: this.state.previewDocument?.JournalEntryID,
                                    token: getJWT().access_token,
                                }))
                            }
                            onError={(e) => {
                                console.log(e)
                            }}
                        />
                    )}
                </ModalDefault>
            </React.Fragment>
        )
    }
}
